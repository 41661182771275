/* eslint-disable no-const-assign */
import { ref, computed } from 'vue'
import th from '@tillhub/javascript-sdk'
import cloneDeep from 'clone-deep'
import compare from 'just-compare'
import { diff } from 'just-diff'

export default {
  setup(data, resourceName, methods = {}, computedProps = {}) {
    // Data
    const identifier = ref(null)
    let initial = ref(cloneDeep(data)) //empty object with default values
    let reference = ref(cloneDeep(data)) //object with data as saved on API
    let current = ref(cloneDeep(data)) //object with user's changed values
    let isLoading = ref(false) //loading state

    // Computed

    //item ID
    const id = computed({
      get() {
        return identifier.value
      },
      set(id) {
        if (id && !id.includes('new')) {
          identifier.value = id
        }
      }
    })
    /*
     * This value should be overwritten, should be a string with the name of the resource
     * for example, 'products', 'staff'...options
     * every thing that @tillhub/javascript-sdk supports.
     */
    const resource = computed(() => resourceName)

    /*
     * Default method name corresponds to the @tillhub/javascript-sdk function name
     * "fetch":  "get"
     * "update": "update"
     * "create": "post"
     * "delete": "delete"
     * Override computed property if a different function name is used,
     * for example override update if the the function is not `update` but `put`
     * th...put() vs. th...update()
     */
    const fetchMethod = computed(() =>
      methods.fetchMethod ? methods.fetchMethod() : 'get'
    )

    const createMethod = computed(() =>
      methods.createMethod ? methods.createMethod() : 'create'
    )

    const updateMethod = computed(() =>
      methods.updateMethod ? methods.updateMethod() : 'put'
    )

    const patchMethod = computed(() =>
      methods.patchMethod ? methods.patchMethod() : 'patch'
    )

    const deleteMethod = computed(() =>
      methods.deleteMethod ? methods.deleteMethod() : 'delete'
    )

    //returns true if new item.
    const isNew = computed(() => !id.value)

    //returns true if changes were made to the data
    const isDirty = computed(() => !compare(reference.value, current.value))

    //returns the list of changes that been made
    const changes = computed(() => diff(reference.value, current.value))

    //computed loading state of the model
    const loading = computed(() => isLoading.value)

    //original values of the model before changes
    const original = computed(() => reference.value)

    /*
     * This will automatically create setter and getter for each attribute initialized.
     * it is necessary so the attributes will be exposed, but will be capsulated in the correct place.
     * for example, if we set an attribute 'name' we will be able to access it (item.name) or set it (item.name='new name')
     * but the attribute will be stored in the current state
     */

    /*Object.keys(data).forEach((key) => {
      model[key] = computed({
        get() {
          console.log('get', key)
          return current.value[key]
        },
        set(value) {
          console.log('set', key, value)
          current.value[key] = value
        }
      })
    })*/

    //add more or overwrite computed function
    //...computed

    // Methods

    /*
     * setting the current state to the reference state.
     * This is good for tracking changes made by the user.
     */
    function sync() {
      reference.value = cloneDeep(current.value)
    }
    /*
     * Resetting model will remove any of the changes made by the user.
     * in case the user made changes and wanted to revert them.
     * By passing a attributeName for a specific property reset
     */
    function reset(attributeName) {
      if (attributeName) {
        const value = cloneDeep(reference.value[attributeName])
        current.value[attributeName] = value
      } else {
        current.value = cloneDeep(reference.value)
      }
    }
    /*
     * Clearing model to the original initialized state.
     * Empty item with default values only.
     */
    function clear() {
      reference.value = cloneDeep(initial.value)
      sync()
    }
    /*
     * 'toJSON' is responsible to define what data will be serialized by JSON.stringify().
     * we override the native toJSON function (Object.prototype.toJSON),
     * so only the current data will be serialized, and return a simple data object.
     * for more information see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#tojson_behavior
     * 'parseDataBeforeSave' is a placeholder function for mutating the data.
     * it should be overwritten in case we need to mutate data before saving (optional).
     */
    function toJSON() {
      return parseDataBeforeSave(cloneDeep(current.value), {
        isNew: isNew.value
      })
    }
    const parseDataBeforeSave =
      methods.parseDataBeforeSave ||
      function (data) {
        return data
      }

    /*
     * This function receives row data from the API. Since we want only parts of the data and not the full object,
     * We are getting the attribute keys from the initial state and setting each attribute via its setter function.
     * by `this[key] = data[key]`
     * the benefit of doing it in that way is to use the reactivity system.
     * After setting the changes in the current state, we sync the changes to the reference state.
     * which means there is no different between the local data and the data on the server.
     */
    function updateModel(data) {
      Object.keys(initial.value).forEach((key) => {
        current.value[key] = data[key]
      })
      sync()
    }

    /*
     * parseResponse is for the response from the API.
     * The response from the server is wrapped in metadata, this function should return the data only.
     * This function should be overwritten if the return data is wrapped differently
     */
    const parseResponse =
      methods.parseResponse ||
      function (response) {
        return response?.data || {}
      }

    //******************** Handle Fetch **********************

    async function fetch(options) {
      if (isNew.value) return //do nothing if item is new
      try {
        isLoading.value = true
        const response = await onFetch({ id: id.value, options }) //doing the get call
        const data = parseResponse(response)
        updateModel(data)
        return { data }
      } catch (e) {
        //logException(e, { trackError: false })
        return { error: e }
      } finally {
        isLoading.value = false
      }
    }

    async function onFetch({ id, options }) {
      //function should be overwritten if different API call is used
      //this function is try/catch protected
      const api = th[resource.value]?.(options)
      //resourceOptions are for additional url query parameters
      if (options?.resourceOptions) {
        return await api[fetchMethod.value](id, options.resourceOptions)
      } else {
        return await api[fetchMethod.value](id)
      }
    }

    //******************** Handle Save **********************

    async function save(options = {}) {
      try {
        isLoading.value = true
        const response = isNew.value
          ? await onCreate({ data: toJSON(), ...options })
          : await onUpdate({
              id: id.value,
              data: toJSON(),
              ...options
            })
        const data = parseResponse(response)
        updateModel(data)
        if (data.id) {
          //set the id of the model
          id.value = data.id
        }
        return { data }
      } catch (e) {
        //logException(e, { trackError: false })
        return { error: e }
      } finally {
        isLoading.value = false
      }
    }
    async function onCreate({ data, createQuery }) {
      //function should be overwritten if different API call is used
      //this function is try/catch protected
      const inst = th[resource.value]?.()
      if (createQuery) {
        return await inst[createMethod.value](data, createQuery)
      }
      return await inst[createMethod.value](data)
    }
    async function onUpdate({ id, data }) {
      //function should be overwritten if different API call is used
      //this function is try/catch protected
      return await th[resource.value]?.()[updateMethod.value](id, data)
    }

    //******************** Handle Patch **********************
    async function patch(options = {}) {
      try {
        isLoading.value = true
        const response = isNew.value
          ? await onCreate({ data: toJSON(), ...options })
          : await onPatch({
              id: id.value,
              ...toJSON(),
              ...options
            })
        const data = parseResponse(response)
        updateModel(data)
        if (data.id) {
          //set the id of the model
          id.value = data.id
        }
        return { data }
      } catch (e) {
        //logException(e, { trackError: false })
        return { error: e }
      } finally {
        isLoading.value = false
      }
    }
    async function onPatch(target) {
      //function should be overwritten if different API call is used
      //this function is try/catch protected
      const source = { id: id.value, ...reference.value }
      return await th[resource.value]?.()[patchMethod.value](source, target)
    }

    //******************** Handle Delete **********************
    async function deleteModel(options = {}) {
      try {
        return (await onDelete({ id: id.value, ...options })) || {}
      } catch (error) {
        return { error }
      }
    }
    async function onDelete({ id }) {
      //function should be overwritten if different API call is used
      //this function is try/catch protected
      return await th[resource.value]?.()[deleteMethod.value](id)
    }

    //add more or overwrite methods function
    //...methods

    // Return
    return {
      id,
      identifier,
      initial,
      reference,
      current,
      isLoading,
      fetchMethod,
      createMethod,
      updateMethod,
      patchMethod,
      deleteMethod,
      isNew,
      isDirty,
      changes,
      loading,
      original,
      sync,
      reset,
      clear,
      toJSON,
      parseDataBeforeSave,
      updateModel,
      parseResponse,
      fetch,
      onFetch,
      save,
      onCreate,
      onUpdate,
      patch,
      onPatch,
      deleteModel,
      onDelete,
      ...methods,
      ...computedProps
    }
  }
}
